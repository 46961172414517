import { createContext, useState } from 'react';

const ComputeContext = createContext({
	servers: {},
	setCompute: function(servers) {}
});

function search(nameKey, myArray) {
	for (var i = 0; i < myArray.length; i++) {
		if (myArray[i].server === nameKey) {
			return i;
		}
	}
}

export function ComputeContextProvider(props) {
	const [ computeNeeded, setComputeNeeded ] = useState([]);

	async function setComputeHandler(servers) {
		const resultObject = search(servers.server, computeNeeded);
		let curComputeNeeded = computeNeeded;

		if (resultObject == null) {
			curComputeNeeded.push(servers);
			setComputeNeeded(computeNeeded);
		} else {
			let newArray = curComputeNeeded.filter((item) => item.server !== servers.server);
			newArray.push(servers);
			setComputeNeeded(newArray);
		}
	}

	const context = {
		servers: computeNeeded,
		setCompute: setComputeHandler
	};

	return <ComputeContext.Provider value={context}>{props.children}</ComputeContext.Provider>;
}

export default ComputeContext;
