import { useState } from 'react';

function useShowMenu(initialVal) {
	const [ state, setState ] = useState(initialVal);
	const showMenu = () => {
		setState(true);
	};
	return [ state, showMenu ];
}

export default useShowMenu;
