import React, { Fragment, useState, useContext, useEffect } from 'react';
import Specs from './Specs';
import ComputeContext from './store/ComputeContext';

function VmSize(props) {
	const [ idx, setIdx ] = useState(0);
	const [ qty, setQty ] = useState(0);

	const computeCtx = useContext(ComputeContext);

	function sizeSelect(e) {
		let idx = e.nativeEvent.target.selectedIndex;
		setIdx(idx);
	}

	function qtySelect(e) {
		let qty = e.nativeEvent.target.selectedIndex;
		setQty(qty);
	}

	const t = props.size;

	const { Server_Name, cpu_qty, disk_size, cpu_spec, ram, networkio, storageio } = t[idx];

	const compu_cpu_qty = cpu_qty * qty;
	const compu_disk_size = disk_size * qty;
	const compu_cpu_spec = cpu_spec * qty;
	const compu_ram = ram * qty;
	const compu_networkio = networkio * qty;
	const compu_storageio = storageio * qty;

	const serv_specs = {
		server: Server_Name,
		cpu_qty: compu_cpu_qty,
		disk_size: compu_disk_size,
		cpu_spec: compu_cpu_spec,
		ram: compu_ram,
		networkio: compu_networkio,
		storageio: compu_storageio,
		server_qty: qty
	};

	useEffect(
		() => {
			computeCtx.setCompute(serv_specs);
		},
		[ idx, qty ]
	);

	let sizes = Object.entries(t).map(([ key, value ]) => {
		return (
			<option key={key} value={value}>
				{value.Size_Name}
			</option>
		);
	});

	const specs = sizes[idx].props.value;
	let sizedp = false;

	if (sizes.length === 1) {
		sizedp = true;
	}
	return (
		<Fragment>
			<td>
				<select disabled={sizedp} onChange={(e) => sizeSelect(e)}>
					{sizes}
				</select>
			</td>
			<td>
				<select onChange={(e) => qtySelect(e)}>
					<option key="0" value="0" defaultValue>
						0
					</option>
					<option key="1" value="1">
						1
					</option>
					<option key="2" value="2">
						2
					</option>
					<option key="3" value="3">
						3
					</option>
					<option key="4" value="4">
						4
					</option>
					<option key="5" value="5">
						5
					</option>
				</select>
			</td>
			<Specs specs={specs} qty={qty} />
		</Fragment>
	);
}

export default VmSize;
