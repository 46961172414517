const prices = {
	cost: {
		vcpu_unreserved_cost: 1.00,
		vcpu_reserved_cost: 1.50,
		ram_unreserved_cost: 0.50,
		ram_reserved_cost: 1.00,
		hdd_cost: 0.20,
		ip_cost: 0.75
	},
	minSell: {
		vcpu_unreserved_minsell: 4.00,
		vcpu_reserved_minsell: 5.00,
		ram_unreserved_minsell: 3.00,
		ram_reserved_minsell: 4.00,
		hdd_minsell: 1.00,
		ip_minsell: 2.00
	},
	recSell: {
		vcpu_unreserved_sell: 6.00,
		vcpu_reserved_sell: 7.00,
		ram_unreserved_sell: 5.00,
		ram_reserved_sell: 6.00,
		hdd_sell: 1.00,
		ip_sell: 2.00
	}
};

export default prices;
