import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { PageLayout } from './components/PageLayout';
import Container from 'react-bootstrap/Container';
import { Providers, ProviderState } from '@microsoft/mgt-element';
import VirtTech from './VirtTech';
import { ComputeContextProvider } from './store/ComputeContext';
import { HostingContextProvider } from './store/HostingContext';
import { CostsContextProvider } from './store/CostsContext';

function useIsSignedIn() {
	const [ isSignedIn, setIsSignedIn ] = useState(false);

	useEffect(() => {
		const updateState = () => {
			const provider = Providers.globalProvider;
			setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
		};

		Providers.onProviderUpdated(updateState);
		updateState();

		return () => {
			Providers.removeProviderUpdatedListener(updateState);
		};
	}, []);

	return [ isSignedIn ];
}

function App() {
	const [ isSignedIn ] = useIsSignedIn();
	return (
		<PageLayout>
			<CostsContextProvider>
				<HostingContextProvider>
					<ComputeContextProvider>
						<div>
							<Container>{isSignedIn ? <VirtTech /> : <p>Please log in</p>}</Container>
						</div>
					</ComputeContextProvider>
				</HostingContextProvider>
			</CostsContextProvider>
		</PageLayout>
	);
}

export default App;
