import React, { Fragment, useContext } from 'react';
import CostsContext from './store/CostsContext';

function TotalCosts(props) {
	const costsCtx = useContext(CostsContext);

	let comptecostTotal = costsCtx.costs.reduce(function(prev, cur) {
		return prev + cur.cost;
	}, 0);
	let compteminsellTotal = costsCtx.costs.reduce(function(prev, cur) {
		return prev + cur.minsel;
	}, 0);
	let comptesellTotal = costsCtx.costs.reduce(function(prev, cur) {
		return prev + cur.sell;
	}, 0);

	let costTotal = Number(costsCtx.ipCosts.cost) + Number(comptecostTotal);
	let minsellTotal = Number(costsCtx.ipCosts.minSell) + Number(compteminsellTotal);
	let sellTotal = Number(costsCtx.ipCosts.sell) + Number(comptesellTotal);

	return (
		<Fragment>
			<td>£{costTotal.toFixed(2)}</td>
			<td>£{minsellTotal.toFixed(2)}</td>
			<td>£{sellTotal.toFixed(2)}</td>
		</Fragment>
	);
}

export default TotalCosts;
