import React, { Fragment, useContext } from 'react';
import ComputeCosts from './ComputeCosts';
import ComputeContext from './store/ComputeContext';
import HostingContext from './store/HostingContext';

function TotalCompute() {
	const computeCtx = useContext(ComputeContext);
	const hostingCtx = useContext(HostingContext);

	const t = computeCtx.servers;
	const h = hostingCtx.cavCloud.cavCloud;

	var cpuTotal = t.reduce(function(prev, cur) {
		return prev + cur.cpu_qty;
	}, 0);
	var diskTotal = t.reduce(function(prev, cur) {
		return prev + cur.disk_size;
	}, 0);
	var cpuSpecTotal = t.reduce(function(prev, cur) {
		return prev + cur.cpu_spec;
	}, 0);
	var ramTotal = t.reduce(function(prev, cur) {
		return prev + cur.ram;
	}, 0);
	var networkTotal = t.reduce(function(prev, cur) {
		return prev + cur.networkio;
	}, 0);
	var storageTotal = t.reduce(function(prev, cur) {
		return prev + cur.storageio;
	}, 0);

	let totals = {
		cpu_qty: cpuTotal,
		disk_size: diskTotal,
		cpu_spec: cpuSpecTotal,
		ram: ramTotal,
		networkio: networkTotal,
		storageio: storageTotal
	};
	let totValues = Object.entries(totals).map(([ key, value ]) => {
		return <td key={key}>{value}</td>;
	});

	return (
		<Fragment>
			{totValues}
			{h ? (
				<Fragment>
					<td />
					<ComputeCosts />
				</Fragment>
			) : null}
		</Fragment>
	);
}

export default TotalCompute;
