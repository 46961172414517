import { createContext, useState } from 'react';

const CostsContext = createContext({
	costs: {},
	ipCosts: {},
	setCosts: function(vmCosts) {},
	setIpCosts: function(ipCosts) {}
});

function search(nameKey, myArray) {
	for (var i = 0; i < myArray.length; i++) {
		if (myArray[i].server === nameKey) {
			return i;
		}
	}
}

export function CostsContextProvider(props) {
	const [ totalCosts, setTotalCosts ] = useState([]);
	const [ totalIpCosts, setTotalIpCosts ] = useState({});

	function setCostsHandler(vmCosts) {
		const resultObject = search(vmCosts.server, totalCosts);
		let curTotalCosts = totalCosts;

		if (resultObject == null) {
			curTotalCosts.push(vmCosts);
			setTotalCosts(curTotalCosts);
		} else {
			let newArray = curTotalCosts.filter((item) => item.server !== vmCosts.server);
			newArray.push(vmCosts);
			setTotalCosts(newArray);
		}
	}

	function setIpCostsHandler(ipCosts) {
		setTotalIpCosts(ipCosts);
	}

	const context = {
		costs: totalCosts,
		ipCosts: totalIpCosts,
		setCosts: setCostsHandler,
		setIpCosts: setIpCostsHandler
	};

	return <CostsContext.Provider value={context}>{props.children}</CostsContext.Provider>;
}

export default CostsContext;
