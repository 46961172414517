import React, { useState, useContext, useEffect } from 'react';
import CostsContext from './store/CostsContext';
import Prices from './data/prices';

function IpCalcs(props) {
	const [ ipCosts, setIpCosts ] = useState({});
	const costsCtx = useContext(CostsContext);

	useEffect(
		() => {
			setIpCosts(totalIpCosts);
		},
		[ props ]
	);

	useEffect(
		() => {
			costsCtx.setIpCosts(ipCosts);
		},
		[ ipCosts ]
	);

	const t = props.total;
	const { cost, minSell, recSell } = Prices;
	const { ip_cost } = cost;
	const { ip_minsell } = minSell;
	const { ip_sell } = recSell;

	const totCost = (t * ip_cost).toFixed(2);
	const totminsell = (t * ip_minsell).toFixed(2);
	const totsell = (t * ip_sell).toFixed(2);

	const totalIpCosts = { cost: totCost, minSell: totminsell, sell: totsell };
	return (
		<React.Fragment>
			<td>£{totCost}</td>
			<td>£{totminsell}</td>
			<td>£{totsell}</td>
		</React.Fragment>
	);
}

export default IpCalcs;
