import React, { Fragment, useContext } from 'react';
import ComputeContext from './store/ComputeContext';
import HostingContext from './store/HostingContext';
import IpCalcs from './IpCalcs';

function search(nameKey, myArray) {
	for (var i = 0; i < myArray.length; i++) {
		if (myArray[i].server === nameKey) {
			return myArray[i].server_qty;
		}
	}
}

function TotalIps(props) {
	const computeCtx = useContext(ComputeContext);
	const hostingCtx = useContext(HostingContext);

	const t = computeCtx.servers;
	const h = hostingCtx.cavCloud.cavCloud;

	const mbgTwTotal = search('MBG Teleworker', t);
	const mbgSipTotal = search('MBG SIP', t);
	const routerTotal = search('Mikrotik CHR', t);

	const ipTotal = mbgTwTotal + mbgSipTotal + routerTotal;

	let total = 0;

	if (ipTotal) {
		total = ipTotal;
	}

	return (
		<Fragment>
			<td colSpan="6">{total}</td>
			{h ? (
				<Fragment>
					<td />
					<IpCalcs total={total} />
				</Fragment>
			) : null}
		</Fragment>
	);
}

export default TotalIps;
