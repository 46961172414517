import React, { Fragment, useContext } from 'react';
import CostsContext from './store/CostsContext';

function ComputeCosts() {
	const costsCtx = useContext(CostsContext);

	let costTotal = costsCtx.costs.reduce(function(prev, cur) {
		return prev + cur.cost;
	}, 0);
	let minsellTotal = costsCtx.costs.reduce(function(prev, cur) {
		return prev + cur.minsel;
	}, 0);
	let sellTotal = costsCtx.costs.reduce(function(prev, cur) {
		return prev + cur.sell;
	}, 0);

	return (
		<Fragment>
			<td>£{costTotal.toFixed(2)}</td>
			<td>£{minsellTotal.toFixed(2)}</td>
			<td>£{sellTotal.toFixed(2)}</td>
		</Fragment>
	);
}

export default ComputeCosts;
