import { createContext, useState } from 'react';

const HostingContext = createContext({
	cavCloud: true,
	setHosting: function(hosting) {}
});

export function HostingContextProvider(props) {
	const [ hosting, setHosting ] = useState({ cavCloud: true });

	function setHostingHandler(hosting) {
		if (hosting === 'Cavendish Cloud') {
			setHosting({ cavCloud: true });
		} else {
			setHosting({ cavCloud: false });
		}
	}

	const context = {
		cavCloud: hosting,
		setHosting: setHostingHandler
	};

	return <HostingContext.Provider value={context}>{props.children}</HostingContext.Provider>;
}

export default HostingContext;
