import React, { Fragment, useContext } from 'react';
import VmSize from './VmSize';
import TotalCompute from './TotalCompute';
import TotalIps from './TotalIps';
import TotalCosts from './TotalCosts';
import HostingContext from './store/HostingContext';

function Vm(props) {
	const hostingCtx = useContext(HostingContext);

	const h = hostingCtx.cavCloud.cavCloud;

	const t = props.tech;
	const unique = [ ...new Set(t.map((item) => item.Server_Name)) ];

	return (
		<Fragment>
			{Object.entries(unique).map(([ key, value ]) => {
				const sizes = t.filter((i) => i.Server_Name === value);
				return (
					<Fragment key={key}>
						<tr>
							<td>{value}</td>
							<VmSize size={sizes} />
						</tr>
					</Fragment>
				);
			})}
			<tr>
				<td colSpan="9">Total Compute Needed:</td>
				<td />
				<TotalCompute />
			</tr>
			<tr>
				<td colSpan="9">Total Public IP Addresses Needed:</td>
				<td />
				<TotalIps />
			</tr>

			{h ? (
				<Fragment>
					<tr>
						<td colSpan="16">Total Costs</td>
						<td />
						<TotalCosts />
					</tr>
				</Fragment>
			) : null}
		</Fragment>
	);
}

export default Vm;
