import React, { Fragment, useContext } from 'react';
import HostingContext from './store/HostingContext';
import VmCalcs from './VmCalcs';

function Specs(props) {
	const hostingCtx = useContext(HostingContext);

	const h = hostingCtx.cavCloud.cavCloud;

	const t = props.specs;
	const qty = props.qty;
	const { req_reserve } = t;

	let sizes = Object.entries(t).map(([ key, value ]) => {
		if (
			key === 'cpu_qty' ||
			key === 'disk_size' ||
			key === 'cpu_spec' ||
			key === 'ram' ||
			key === 'networkio' ||
			key === 'storageio'
		) {
			return (
				<Fragment key={key}>
					<td>{value}</td>
				</Fragment>
			);
		} else {
			return null;
		}
	});
	let calcs = Object.entries(t).map(([ key, value ]) => {
		if (
			key === 'cpu_qty' ||
			key === 'disk_size' ||
			key === 'cpu_spec' ||
			key === 'ram' ||
			key === 'networkio' ||
			key === 'storageio'
		) {
			let subtotal = value * qty;
			return (
				<Fragment key={key}>
					<td>{subtotal}</td>
				</Fragment>
			);
		} else {
			return null;
		}
	});

	return (
		<Fragment>
			{sizes}
			<td />
			{calcs}
			{h ? (
				<Fragment>
					<td />
					<VmCalcs qty={qty} reserve={req_reserve} specs={t} />
				</Fragment>
			) : null}
		</Fragment>
	);
}

export default Specs;
