import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

import { Login } from '@microsoft/mgt-react'

export const PageLayout = (props) => {
    return (
        <>
            <Navbar bg="primary" variant="dark">
                <Container>
                <Navbar.Brand>Cavendish Virtual Calculator</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Login />
                </Container>
            </Navbar>
            <br />
            <br />
            {props.children}
        </>
    );
};