import React, { useState, useEffect, useContext, Fragment } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import useShowMenu from "./hooks/useShowMenu";
import Vm from "./Vm";
import "./VirtTech.css";
import axios from "axios";
import HostingContext from "./store/HostingContext";

// axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";f

// const api_server = "http://20.26.51.161:3000"; //process.env.REACT_APP_APIURL;

function VirtTech() {
  const [menutitle, setMenuTitle] = useState(
    "Select Virtualisation technology"
  );
  const [menuShow, showMenu] = useShowMenu(false);
  const [data, setData] = useState("");
  const [virtualTech, setVirtualTech] = useState("hyperv");

  const hostingCtx = useContext(HostingContext);

  useEffect(() => {
    async function getData() {
      const apispecs = await axios.get(
        // api_server + "/api/specs/" + virtualTech
        "/api/specs/" + virtualTech
      );
      setData(apispecs.data);
    }
    getData();
  }, [virtualTech]);

  async function menuSelect(e, virtualisation) {
    setMenuTitle(e.target.textContent);
    hostingCtx.setHosting(e.target.textContent);
    showMenu(true);
    setVirtualTech(virtualisation);
  }
  return (
    <Fragment>
      <DropdownButton id="dropdown-basic-button" title={menutitle}>
        <Dropdown.Item as="button">
          <div onClick={(e) => menuSelect(e, "hyperv")}>Cavendish Cloud</div>
        </Dropdown.Item>
        <Dropdown.Item as="button">
          <div onClick={(e) => menuSelect(e, "vmware")}>VMWare</div>
        </Dropdown.Item>
        <Dropdown.Item as="button">
          <div onClick={(e) => menuSelect(e, "hyperv")}>Hyper-V</div>
        </Dropdown.Item>
      </DropdownButton>
      <br />
      <br />
      {menuShow ? (
        <table>
          <tbody>
            <tr>
              <th colSpan="3" />
              <th colSpan="2">Configuration</th>
              <th colSpan="2">Reservations</th>
              <th colSpan="2">Expected Usage</th>
              <th />
              <th colSpan="2">Configuration</th>
              <th colSpan="2">Reservations</th>
              <th colSpan="2">Expected Usage</th>
              {menutitle === "Cavendish Cloud" ? (
                <Fragment>
                  <th />
                  <th colSpan="3">Cavendish Prices</th>
                </Fragment>
              ) : null}
            </tr>
            <tr>
              <th>Appliance</th>
              <th>System Capacity</th>
              <th>Qty</th>
              <th>vCPU</th>
              <th>Disk - GB</th>
              <th>CPU - GHz</th>
              <th>Memory - GB</th>
              <th>Network I/O - mb/S</th>
              <th>Storage I/O - iops</th>
              <th />
              <th>vCPU</th>
              <th>Disk - GB</th>
              <th>CPU - GHz</th>
              <th>Memory - GB</th>
              <th>Network I/O - mb/S</th>
              <th>Storage I/O - iops</th>
              {menutitle === "Cavendish Cloud" ? (
                <Fragment>
                  <th />
                  <th>Cost Price</th>
                  <th>Minimum Sell Price</th>
                  <th>Recommended Sell Price</th>
                </Fragment>
              ) : null}
            </tr>
            <Vm tech={data} />
          </tbody>
        </table>
      ) : null}
    </Fragment>
  );
}

export default VirtTech;
