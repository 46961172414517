import React, { useState, useContext, useEffect, Fragment } from 'react';
import Prices from './data/prices';
import CostsContext from './store/CostsContext';

function VmCalcs(props) {
	const [ vmCosts, setVmCosts ] = useState({});

	const costsCtx = useContext(CostsContext);

	useEffect(
		() => {
			setVmCosts(totals);
		},
		[ props ]
	);

	useEffect(
		() => {
			costsCtx.setCosts(totals);
		},
		[ vmCosts ]
	);

	const t = props.qty;
	const reserve = props.reserve;
	const specs = props.specs;
	const { cost, minSell, recSell } = Prices;
	const { vcpu_unreserved_cost, vcpu_reserved_cost, ram_unreserved_cost, ram_reserved_cost, hdd_cost } = cost;
	const {
		vcpu_unreserved_minsell,
		vcpu_reserved_minsell,
		ram_unreserved_minsell,
		ram_reserved_minsell,
		hdd_minsell
	} = minSell;
	const { vcpu_unreserved_sell, vcpu_reserved_sell, ram_unreserved_sell, ram_reserved_sell, hdd_sell } = recSell;
	let cpu_cost = 0;
	let cpu_minsell = 0;
	let cpu_sell = 0;
	let ram_cost = 0;
	let ram_minsell = 0;
	let ram_sell = 0;
	if (reserve) {
		cpu_cost = specs.cpu_qty * t * vcpu_reserved_cost;
		cpu_minsell = specs.cpu_qty * t * vcpu_reserved_minsell;
		cpu_sell = specs.cpu_qty * t * vcpu_reserved_sell;
		ram_cost = specs.ram * t * ram_reserved_cost;
		ram_minsell = specs.ram * t * ram_reserved_minsell;
		ram_sell = specs.ram * t * ram_reserved_sell;
	} else {
		cpu_cost = specs.cpu_qty * t * vcpu_unreserved_cost;
		cpu_minsell = specs.cpu_qty * t * vcpu_unreserved_minsell;
		cpu_sell = specs.cpu_qty * t * vcpu_unreserved_sell;
		ram_cost = specs.ram * t * ram_unreserved_cost;
		ram_minsell = specs.ram * t * ram_unreserved_minsell;
		ram_sell = specs.ram * t * ram_unreserved_sell;
	}
	const disk_cost = specs.disk_size * t * hdd_cost;
	const disk_minsell = specs.disk_size * t * hdd_minsell;
	const disk_sell = specs.disk_size * t * hdd_sell;

	const totCost = (cpu_cost + ram_cost + disk_cost).toFixed(2);
	const totminsell = (cpu_minsell + ram_minsell + disk_minsell).toFixed(2);
	const totsell = (cpu_sell + ram_sell + disk_sell).toFixed(2);

	const totals = {
		server: specs.Server_Name,
		cost: Number(totCost),
		minsel: Number(totminsell),
		sell: Number(totsell)
	};
	return (
		<Fragment>
			<td>£{totCost}</td>
			<td>£{totminsell}</td>
			<td>£{totsell}</td>
		</Fragment>
	);
}

export default VmCalcs;
